/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {
  $('.menu__mobile .dropdown').on('show.bs.dropdown', function(e) {
    $(this).find('.dropdown-menu').first().slideDown(300);
  });
  $('.menu__mobile .dropdown').on('hide.bs.dropdown', function(e) {
    $(this).find('.dropdown-menu').first().slideUp(0);
  });
  $('.read__more').on('click', function(){
    $(this).parent().parent().find('.visible__read__more').slideDown(800);
    $(this).hide(800);
  });
  $('.prevent-default').on('click', function(e){
    e.preventDefault();
  });
  $('.openModalHotelInterna').on('click', function(){
    urlVideo = $('#iframeVideoHotelInterna').data('urlvideo');
    $('#iframeVideoHotelInterna').attr('src', urlVideo);
  });
  $('#modalVideoHotelInterna').on('hide.bs.modal', function () {
    $('#iframeVideoHotelInterna').attr('src', '');
  });
  $('.button__meetings__video').on('click', function(){
    data_src = $('#modalVideo iframe').data('video');
    $('#modalVideo iframe').attr('src', data_src);
  });
  $('#modalVideo').on('hide.bs.modal', function () {
    $('#modalVideo iframe').attr('src', '');
  });


  carousels = $('.slider__custom__dots').length;
  var array = []; 
  for (i = 1; i <= carousels; i++) {
    var tmp = $('#owl'+i).owlCarousel({
      loop: true,
      autoplay: true,
      items: 1,
      nav: true,
      touchDrag:false,
      mouseDrag:false,
      autoplayHoverPause: true,
      animateOut: 'slideOutUp',
      animateIn: 'slideInUp',
      autoplayTimeout: 25000,
      navText: ["<img src='https://velasmeetingsmexico.com/app/uploads/2019/08/up-arrow.png'>","<img src='https://velasmeetingsmexico.com/app/uploads/2019/08/down-arrow.png'>"],
      dotsContainer: '.dots__main__atractions__'+i
    });
    array.push(tmp);
    $('section.main__atractions .dots__main__atractions__'+i).on('click', 'li', function(e) {
      var data = $(this).data();
      array[data.parent - 1].trigger('to.owl.carousel', [$(this).index(), 300]);
    });
  }
  var carouselChefs = $('.owl-carousel.carousel__chef');

  carouselChefs.children().each( function( index ) {
  $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
});
  carouselChefs.owlCarousel({
    center: true,
    loop: true,
    nav: true,
    dots:false,
    navText: ["<img src='https://velasmeetingsmexico.com/app/uploads/2019/08/left-arrow.png'>","<img src='https://velasmeetingsmexico.com/app/uploads/2019/08/right-arrow.png'>"],
    responsive:{
      0:{
        items:1,
      },
      600:{
        items:3,
      },
      1000:{
        items:5
      }
    }

  });
  $('.owl-carousel.carousel__chef').on('click', '.owl-item>div', function() {
   carouselChefs.trigger('to.owl.carousel', $(this).data( 'position' ) );
   position = +$(this).data('position') + 1;
   $('.nav-tabs a[href="#chef-'+position+'"]').tab('show');
 });

  carouselChefs.on('changed.owl.carousel', function(e) {
    p = e.item.index - 4;
    $('.nav-tabs a[href="#chef-'+p+'"]').tab('show');
  });

  $('.tabs__chefs .read__more').on('click', function(){
    $(this).slideUp('slow');
    $(this).parent().find('.more__information').slideDown('slow');
  });

  $('.slider__pages').each(function(){
    var elemento = $(this);
    var slider = $(this).parent().find('.owl-carousel');
    $(elemento).prependTo(slider);
  });
  
  //dots de carousel de bootstrap funcionando
  $('ol.carousel-indicators > li').on('click', function(){
    slide = $(this).data('slide-to');
    $(this).parent().parent().carousel(slide);
  });
  $('.velas__resorts__experience__slider').owlCarousel({
    loop:true,
    margin:0,
    nav:true,
    dots: false,
    navText: ['<i class="glyphicon glyphicon-menu-left">','<i class="glyphicon glyphicon-menu-right">'],
    responsive:{
      0:{
        items:1
      },
      600:{
        items:3
      },
    }
  });
  

  //----------------------------------------------------------//
  $('.boton__hamburguesa.mobil').click(function(){
    $(this).toggleClass('open');
    $('.menu__mobile').toggleClass('active');
  });
  $('.promociones__highlights__carousel').owlCarousel({
    loop:true,
    margin:5,
    responsiveClass:true,
    nav:true,
    dots: false,
    navText: ["<i class='glyphicon glyphicon-menu-left'></i>","<i class='glyphicon glyphicon-menu-right'></i>"],
    responsive:{
      0:{
        items:1,
      },
      900:{
        items:3,
      },
      1500:{
        items:4,
      },
      1800:{
        items:5,
      }
    }
  });
  $('.slider__inicio').owlCarousel({
    loop:true,
    margin:0,
    nav:true,
    dots: false,
    autoplay:true,
    autoplayTimeout:5000,
    smartSpeed: 2000,
    items:1,
    navText: ["<i class='glyphicon glyphicon-menu-left'></i>","<i class='glyphicon glyphicon-menu-right'></i>"],
  }); 
  $('#menu-nuevo-menu-principal > li:nth-child(4) a').prepend('<i class="fa fa-globe"></i>');

  // if($(window).width() < 600)
  // {
  // } else {
  //   $('video source').attr('src', 'https://ohmarketing.nyc3.digitaloceanspaces.com/velas-resorts-meetings-2019.mp4');
  // }



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.tab-flex li.active').each(function(){
         var parent = $(this).scrollParent ? $(this).scrollParent() : $(this).parent();
         var scrollOffSet = $(this).offset().left;
         parent.scrollLeft(scrollOffSet);
       });

        $('.stick_in_parent').stick_in_parent();

        /*$("img.lazy").lazyload({
            effect : "fadeIn",
            event : "sporty"
        });
        $(window).bind("load", function() {
            var timeout = setTimeout(function() {
                $("img.lazy").trigger("sporty");
            }, 5000);
          });*/

        /*$("img.lazy").unveil(200, function() {
          $(this).load(function() {
            var parent = $(this).parent().parent();
            if(parent.hasClass('loading')) {
              parent.removeClass('loading');
            }
          });
        });*/
        
        var owl = $('#carousel-home');
        owl.owlCarousel({
          loop:false,
          margin:20,
          nav:true,
          autoplayHoverPause:true,
          responsive:{
            0:{
              items:1
            },
            300:{
              items:1
            },
            600:{
              items:2
            },
            1000:{
              items:3
            }
          }
        });
        $(document).ready(function(){
          // Activate Carousel
          $("#reviews").carousel("pause");

          setTimeout(function(){
            $('#loader-text').text("Maybe you want to go for a cup of coffee ;)");
          }, 1300);

          setTimeout(function(){
            $('#loader-text').text("Ready!");
            $('body').addClass('loaded');
          }, 3000);
        });


        $('.owl-next').click(function(){
          owl.trigger('next.owl.carousel');
        });
        $('.owl-prev').click(function(){
          owl.trigger('prev.owl.carousel');
        });

        var options = {controls: ['play-large'], autoplay: true, loop: true, showPosterOnEnd: true, clickToPlay:false}; 
        var players = plyr.setup('#video-carosel #video', options);

        if(players !== false){
          players[0].on('ready', function(event) {
            var instance = event.detail.plyr;
            if(!instance.isMuted()){
              instance.toggleMute();
            }

            $('.controls button').on('click', function(e){
              if($(this).hasClass("play")){
                instance.togglePlay();
                $(this).addClass("active");
                $('.controls .full').removeClass("active");
                $('.controls .mute').removeClass("active");
              }else if($(this).hasClass("mute")){
                instance.toggleMute();
                $(this).addClass("active");
                $('.controls .play').removeClass("active");
                $('.controls .full').removeClass("active");
              }else if($(this).hasClass("full")){
                instance.toggleFullscreen();
                $(this).addClass("active");
                $('.controls .play').removeClass("active");
                $('.controls .mute').removeClass("active");
              }
            });

          });

          players[0].on('pause', function(event) {
            var instance = event.detail.plyr;
            $('.controls .play').html('<i class="fa fa-pause" aria-hidden="true">');
          });

          players[0].on('play', function(event) {
            var instance = event.detail.plyr;
            $('.controls .play').html('<i class="fa fa-play" aria-hidden="true">');
          });

          players[0].on('volumechange', function(event) {
            var instance = event.detail.plyr;
            if(instance.isMuted()){
              $('.controls .mute').html('<i class="fa fa-volume-off" aria-hidden="true">');
            }else{
              $('.controls .mute').html('<i class="fa fa-volume-up" aria-hidden="true">');
            }
          });

          players[0].on('enterfullscreen', function(event) {
            var instance = event.detail.plyr;
            $('.video-content #video').css('transform', 'translateY(0)');
          });

          players[0].on('exitfullscreen', function(event) {
            var instance = event.detail.plyr;
            $('.video-content #video').css('transform', '');
            $('.controls .full').removeClass("active");
          });

          players[0].on('ended', function(event) {
            var instance = event.detail.plyr;
            instance.rewind(instance.getDuration());
            instance.play();
          });

          players[0].on('error', function(event, error){
            var instance = event.detail.plyr;
            console.log(event);
            console.log(error);
          });
        }

        $('.btn-showmore').on('click', function(){
          if(!$(this).hasClass('collapsed')){
            $('.show-background').css('background-color', 'transparent');
            $(this).children('span').children('i.remplace').removeClass('fa-minus').addClass('fa-plus');
          }else{
            $('.show-background').css('background-color', 'rgba(0,0,0,0.50)');
            $(this).children('span').children('i.remplace').removeClass('fa-plus').addClass('fa-minus');
          }
        });

        var opt = {controls: ['play', 'progress', 'volume', 'mute', 'fullscreen'], 
        autoplay: false, showPosterOnEnd: true, clickToPlay:false}; 
        var video_gallery = plyr.setup('.video_media_gallery', opt);

        var videoEvents = function(event) {
          var instance = event.detail.plyr;
          $(instance.getMedia())
          .click(function(){
            instance.toggleFullscreen();
          });
          /*$(instance.getMedia()).parent().parent()
          .mouseenter(function() {
            instance.play();
          })
          .mouseleave(function() {
            instance.pause();
          });*/
        };

        var videoMute = function(event) {
          var instance = event.detail.plyr;
          instance.toggleMute();
        };

        for (var i = 0; i < video_gallery.length; i++) {
          video_gallery[i].on('ready', videoEvents);
          video_gallery[i].on('enterfullscreen', videoMute);
          video_gallery[i].on('exitfullscreen', videoMute);
        }

        var opt2 = {controls: ['play', 'progress', 'volume', 'mute', 'fullscreen'], 
        autoplay: false, showPosterOnEnd: true, clickToPlay:false}; 
        var video_generic = plyr.setup('.video_generic', opt2);

        function voucherSourcetoPrint(source) {
          return "<html><head><title>Velas Meetings ImageViewer</title><script>function step1(){\n" +
          "setTimeout('step2()', 10);}\n" +
          "function step2(){window.print();window.close()}\n" +
          "</scri" + "pt></head><body onload='step1()' style='text-align:center;'>\n" +
          "<img src='" + source + "' /></body></html>";
        }

        $( document).delegate( ".lightbox-print", "click", function(e){
          Pagelink = "about:blank";
          var w = 600;
          var h = 300;
          var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
          var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

          var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
          var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

          var left = ((width / 2) - (w / 2)) + dualScreenLeft;
          var top = ((height / 2) - (h / 2)) + dualScreenTop;
          var pwa = window.open(Pagelink, "_blank", "width="+w+",height="+h+",resizable=no,menubar=no,location=no,fullscreen=no,left="+left+",top="+top);
          pwa.document.open();
          pwa.document.write(voucherSourcetoPrint($('.lightbox-image').attr('src')));
          pwa.document.close();
        });

        function remplaceHrefOnGallery(){
          $('.gallery-meetings a.page-numbers').each(function() {
            if(window.location.hash !== undefined && window.location.hash !== ''){
              var url = $(this).attr('href');
              url = url.split("#")[0];
              $(this).attr('href', url + window.location.hash);
            }
          });
        }

        // Tabs URL Fix
        $(function(){
          var hash = window.location.hash;
          if(hash) {$('ul.nav a[href="' + hash + '"]').tab('show');}

          $('.nav-tabs a').click(function (e) {
            $(this).tab('show');
            var scrollmem = $('body').scrollTop() || $('html').scrollTop();
            window.location.hash = this.hash;
            $('html,body').scrollTop(scrollmem);

            remplaceHrefOnGallery();
          });
        });

        remplaceHrefOnGallery();

        $('.my-toggle').click(function(){
          var toggle = $(this).data('toggle');
          if(toggle !== undefined && toggle !== ''){
            toggle = $('#'+toggle);
            if(toggle.hasClass('active')){
              $("html, body").animate({ scrollTop: toggle.offset().top - 100 }, "slow");
              toggle.css('max-height', '140px').removeClass('active');
              $('.hidden-accordion-js').attr('style', 'display: none !important;');
              $(this).children('span').children('i.remplace').removeClass('fa-minus').addClass('fa-plus');
            }else{
              toggle.css('max-height', 'initial').addClass('active');
              $('.hidden-accordion-js').attr('style', 'display: block !important;');
              $(this).children('span').children('i.remplace').removeClass('fa-plus').addClass('fa-minus');
            }
          }
        });

        $( window ).resize(function() {
          var toggle = $('.my-toggle').data('toggle');
          if( $( window ).width() > 767 ){
            $('#'+toggle).removeAttr('style');
            $('.hidden-accordion-js').removeAttr('style');
          }
        });

        $('.my-accordion').click(function(){
          var toggle = $(this).data('toggle');
          if(toggle !== undefined && toggle !== '') {
            toggle = $('#'+toggle);
            if(toggle.hasClass('active')) {
              $(toggle).removeClass('active').hide(); 
              $(this).children('input[type="image"]').attr('src', '/app/uploads/2016/10/plus-2.png');
              $(this).children('p').children('i').text("Read Description");
              $(toggle).parent().addClass("shadow-inset").removeClass("shadow-inset-2");
            } else {
              $(toggle).addClass('active').show(); 
              $(this).children('input[type="image"]').attr('src', '/app/uploads/2016/10/minus-2.png');
              $(this).children('p').children('i').text("Ocultar DescripciÃ³n");
              $(toggle).parent().removeClass("shadow-inset").addClass("shadow-inset-2");
            }
          }
        });

        $(document).ready(function(){
          var element = $('.touch-animation');
          var target  = $(element.data("target"));
          var modify  = parseInt(element.data("offset"));
          var offset  = element.data("target") === undefined ? 0 : target.offset().top || 0;
          var flag    = true;
          var wwidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

          if (modify !== 'NaN'){
            offset += modify;
          }

          $(window).scroll(function() {
            if (wwidth<=767) {
              if ( window.scrollY >= parseInt(offset) && flag){
                element.fadeIn("slow", function() {
                  $(this).css("display", "block");
                  $('body').bind('touchmove', function(e){e.preventDefault();});
                  flag = false;
                });
              }
            }
          });

          element.click(function() {
            $(this).fadeOut( "slow", function() {
              $(this).remove();
              $('body').unbind('touchmove');
            });
          });
        });

        // JS Tabs

        $('.js-tabs-responsive').responsiveTabs({
          startCollapsed: 'accordion'
        });

        // Reviews

        var getTemplate = function(review) {
          var starsLatter = {'0' : 'zero', '1' : 'one', '2' : 'two', '3' : 'three', '4' : 'fourth', '5' : 'five'};
          var stars = review.review_rate;
          var location = review.review_location;
          var date = review.review_date;
          var author = review.guest_name;
          var edit_url = review.edit_review;
          var temp = '' +
          '<div class="review_container review review-'+review.id+' '+starsLatter[stars]+'-stars" data-review='+review.id+' itemprop="review" itemscope itemtype="http://schema.org/Review">' +
          '<h4 class="review-title" itemprop="name">'+review.title.rendered+edit_url+'</h4>' +
          '<div class="review-stars" itemprop="reviewRating" itemscope itemtype="http://schema.org/Rating">' +
          '<meta itemprop="worstRating" content = "1">' +
          '<meta itemprop="ratingValue" content = "'+stars+'">' +
          '<meta itemprop="bestRating" content = "5">';
          for(var i=1; i<=stars; i++){
            temp += '<i class="fa fa-star" aria-hidden="true"></i>';
          }
          temp += '' +
          '</div>' +
          '<div class="review-content">' +
          '<div class="review-description" itemprop="reviewBody">' +
          '<div class="rendered">' +
          review.excerpt.rendered +
          '</div>' +
          '<a class="readMore" data-review="review-'+review.id+'">Read More</a>' +
          '</div>' +
          '<div class="review-info">' +
          '<div itemprop="author" itemscope itemtype="http://schema.org/Person">' +
          '<p itemprop="name">'+author+'</p>' +
          '</div>' +
          '<p itemprop="datePublished" content="'+date+'">'+date+'</p>' +
          '<div itemprop="itemReviewed" itemscope itemtype="http://schema.org/Hotel">' +
          '<p itemprop="name">'+location+'</p>' +
          '</div>' +
          '</div>' +
          '</div>' +
          '</div>';
          return temp;
        };

        var fillReviews = function(data) {
          $('.reviews_wrapper').append(getTemplate(data.attributes));
        };

        wp.api.loadPromise.done( function() {
          var reviewsCollection = new wp.api.collections.Reviews();
          var query = { data: { 'filter': { 'status': 'publish' } } };
          reviewsCollection.fetch( query ).done(function(object) {
            if(object.length > 0) {
              $('.reviews_loading').remove();
              reviewsCollection.each(function(review,index){
                fillReviews(review);
              });

              $('.readMore').click(function(evt) {
                evt.preventDefault();
                var readMore = $(this);
                var idContent = $(this).data('review');
                if(idContent !== '' && idContent !== undefined ) {
                  var id = $("."+idContent).data('review');
                  if(id === '' || id === undefined){ return; }
                  var post = new wp.api.models.Reviews({id:parseInt(id)});
                  post.fetch().done(function(review) {
                    if(readMore.hasClass('active')) {
                      $('.'+idContent).removeClass('active');
                      $('.'+idContent+' .review-description .rendered').html(review.excerpt.rendered);
                      $('.'+idContent+' .readMore').removeClass('active').text("Read More");
                      $('.'+idContent+' .review-info').css('opacity', '0');
                      setTimeout(function(){$('.'+idContent+' .review-info').show();}, 1990);
                      setTimeout(function(){$('.'+idContent+' .review-info').css('opacity', '1');}, 2000);
                      $('.'+idContent+' .review-description').css('width', '50%');
                    } else {
                      $('.'+idContent).addClass('active');
                      $('.'+idContent+' .review-description .rendered').html(review.content.rendered);
                      $('.'+idContent+' .readMore').addClass('active').text("Back");
                      $('.'+idContent+' .review-info').hide();
                      $('.'+idContent+' .review-description').css('width', '100%');
                    }
                  });
                  post.fetch().fail(function() {
                    console.log("Error obteniendo el contenido de la review " + id);
                  });
                }
              });
            } else {
              $('.reviews_loading').html("" +
                '<i class="fa fa-comments-o fa-3x fa-fw" aria-hidden="true"></i>' +
                '<span class="sr-only">Sin reviews</span>' +
                '<p>Al parecer no tenemos reviews.</p>' +
                '<p>Â¿Tienes alguna buena experiencia con nosotros?</p>' +
                '<p><a href="mailto:info@velasmeetingsmexico.com">!Queremos conocerla!</a></p>');
            }      
          });
          reviewsCollection.fetch().fail(function() {
            $('.reviews_loading').html("" +
              '<i class="fa fa-exclamation-triangle fa-3x fa-fw" aria-hidden="true"></i>' +
              '<span class="sr-only">Fail</span>' +
              '<p>No se han podido cargar los reviews por algun motivo.</p>');
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Meetings Planner Library
    'page_template_template_planner_library': {
      init: function() {
        $('a.a-tab.video-button').on('click', function(e){
          e.preventDefault();
          $('#VimeoVideo').attr('src', $(this).attr('href'));
          $('#myModal').modal('show');
        });

        $('#myModal').on('hidden.bs.modal', function(e){
          $('#VimeoVideo').attr('src', '');
        });

        var url = window.location.href,
        mediaquery = window.matchMedia("(min-width: 720px)");

        if (url === 'http://velasmeetingsmexico.com/meeting-planner-library/#velas-resorts-destinations' && mediaquery.matches){

          $('div.hidden-xs div.container ul.main-planner li a.a-tab').click();
          $('#VimeoVideo').attr('src', 'https://player.vimeo.com/video/204581569');
          $('#myModal').modal('show');
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_gallery': {
      init: function() {
        $('body').ready(function(){
          $('html,body').animate({
            scrollTop: $(".facilities").offset().top},
            'slow');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.


// Acommodations and Calendar RFP.
jQuery(document).ready(function($) {
      //Calendarios RFP
      if($("#calendar").length > 0) {
        $("#calendar").datepicker({minDate: "0"});
        $("#calendar").datepicker('option', {dateFormat: 'mm/dd/yy'});
      }

      if($("#datepicker").length > 0) {
        $("#datepicker").datepicker({minDate: "0"});
        $("#datepicker").datepicker('option', {dateFormat: 'mm/dd/yy'}); 
      }
      $('#dmctabs').responsiveTabs({
       startCollapsed: 'accordion'
     });




    });




/*function Scrolldown() {
window.scroll(0,500);
}
window.onload = Scrolldown;*/

